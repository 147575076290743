import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

import { createTheme, NextUIProvider } from '@nextui-org/react';
import { Session } from 'next-auth';
import { SessionProvider as AuthProvider } from 'next-auth/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';

import type { AppProps } from 'next/app';

import Head from 'next/head';
import { RecoilRoot } from 'recoil';
import { APP_NAME } from '@src/config/constants';

import { AppLayout } from '@src/layouts/app/AppLayout';
import '../styles/globals.css';

// 2. Call `createTheme` and pass your custom values
const lightTheme = createTheme({
  type: 'light',
});

const darkTheme = createTheme({
  type: 'dark',
});

const isBrowser = typeof window !== 'undefined';
const apolloUri = isBrowser ? `${window.location.origin}/api/graphql` : '';
const client = new ApolloClient({
  cache: new InMemoryCache(),
  ssrMode: typeof window === 'undefined',
  uri: apolloUri,
});

export type ApplicationProps = AppProps<{ session?: Session }>;

export function Application(props: ApplicationProps) {
  const { Component, pageProps } = props;
  const { session } = pageProps;

  return (
    <>
      <Head>
        <title>{APP_NAME}</title>
        <meta name="description" content="Pitchblend site" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <AuthProvider session={session}>
        <ApolloProvider client={client}>
          <RecoilRoot>
            <NextThemesProvider
              defaultTheme="dark"
              attribute="class"
              value={{
                dark: darkTheme.className,
                light: lightTheme.className,
              }}
            >
              <NextUIProvider>
                <AppLayout>
                  <Component {...pageProps} />
                  <ToastContainer
                    position="bottom-right"
                    autoClose={false}
                    theme="colored"
                  />
                </AppLayout>
              </NextUIProvider>
            </NextThemesProvider>
          </RecoilRoot>
        </ApolloProvider>
      </AuthProvider>
    </>
  );
}

export default Application;
