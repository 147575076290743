export const APP_NAME = 'Pitchblend';

export const ROUTES = {
  clients: '/clients',
  clientsEdit: '/clients/edit',
  feed: '/feed',
  signIn: '/sign-in',
  stillFollowingHandles: '/still-following-handles',
  tags: '/tags',
  tagsEdit: '/tags/edit',
  unassignedHandles: '/unassigned-handles',
  unauthorized: '/unauthorized',
  users: '/users',
} as const;

export type RoutesKey = keyof typeof ROUTES;
