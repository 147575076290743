import * as React from 'react';
import {
  Button,
  Container,
  Link,
  Navbar,
  Spacer,
  Text,
} from '@nextui-org/react';
import { useRouter } from 'next/router';
import { ROUTES } from '@src/config/constants';
import { AppNavBarLogo } from '@src/layouts/app/AppNavBarLogo';
import { AppNavBarUserDropdown } from '@src/layouts/app/AppNavBarUserDropdown';
import { useSessionUser } from '@src/layouts/app/hooks/useSessionUser';
import { AppThemeToggle } from './AppThemeToggle';

interface AppNavBarProps {}

/**
 * @name AppNavBarGroup
 * @description tbd...
 */
export function AppNavBar(_props: AppNavBarProps) {
  // const {} = props

  // Constants
  const collapseItems = [
    { path: ROUTES.clients, title: 'Campaigns' },
    { path: `${ROUTES.feed}?statuses=UNSET`, title: 'Feed' },
    // { path: ROUTES.tags, title: 'Tags' },
    { path: ROUTES.users, title: 'Users' },
  ];

  // Hooks
  const { isLoadingSessionUser, sessionUser } = useSessionUser();
  const router = useRouter();

  // Setup
  const { pathname } = router;
  const userImage = sessionUser?.image ?? '';
  const email = sessionUser?.email ?? '';

  // Markup
  const renderUserDropdown = () => {
    if (isLoadingSessionUser) {
      return <Container css={{ h: '40px', w: '40px' }}></Container>;
    }

    if (!sessionUser) {
      return (
        <Button auto flat as={Link} href={ROUTES.signIn}>
          Sign in
        </Button>
      );
    }

    return <AppNavBarUserDropdown email={email} userImage={userImage} />;
  };

  return (
    <Navbar variant="sticky">
      <Navbar.Brand>
        <Navbar.Toggle showIn="xs" aria-label="toggle navigation" />
        <Spacer x={1} />
        <Navbar.Brand>
          <Link href="/" color="text">
            <AppNavBarLogo />
            <Text b color="inherit" hideIn="xs">
              Pitchblend
            </Text>
          </Link>
        </Navbar.Brand>
      </Navbar.Brand>
      <Navbar.Content hideIn="xs" variant="underline-rounded">
        <Navbar.Link
          isActive={pathname.includes(ROUTES.clients)}
          href={ROUTES.clients}
        >
          Campaigns
        </Navbar.Link>
        <Navbar.Link
          isActive={pathname.includes(ROUTES.feed)}
          href={`${ROUTES.feed}?statuses=UNSET`}
        >
          Feed
        </Navbar.Link>
        {/* <Navbar.Link
          isActive={pathname.includes(ROUTES.tags)}
          href={ROUTES.tags}
        >
          Tags
        </Navbar.Link> */}
        <Navbar.Link
          isActive={pathname.includes(ROUTES.users)}
          href={ROUTES.users}
        >
          Users
        </Navbar.Link>
      </Navbar.Content>
      <Navbar.Content>
        <Navbar.Item css={{ mr: '$10' }} hideIn="xs">
          <AppThemeToggle />
        </Navbar.Item>
        <Navbar.Item>{renderUserDropdown()}</Navbar.Item>
      </Navbar.Content>
      <Navbar.Collapse showIn="xs">
        {collapseItems.map((item) => (
          <Navbar.CollapseItem key={item.title}>
            <Link
              color="inherit"
              css={{
                minWidth: '100%',
              }}
              href={item.path}
            >
              {item.title}
            </Link>
          </Navbar.CollapseItem>
        ))}
      </Navbar.Collapse>
    </Navbar>
  );
}
