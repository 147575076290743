/* eslint-disable max-lines */
/* eslint-disable @getify/proper-arrows/where */

import React from 'react';

const ChevronDownIcon = ({
  fill,
  height = 24,
  size,
  width = 24,
  ...props
}: {
  fill?: string;
  height?: number;
  size?: number;
  width?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={fill || 'currentColor'}
      height={size || height || 24}
      width={size || width || 24}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
};

const BoltIcon = ({
  fill,
  height = 24,
  size,
  width = 24,
  ...props
}: {
  fill?: string;
  height?: number;
  size?: number;
  width?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={fill || 'currentColor'}
      height={size || height || 24}
      width={size || width || 24}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
      />
    </svg>
  );
};

const FireIcon = ({
  fill,
  height = 24,
  size,
  width = 24,
  ...props
}: {
  fill?: string;
  height?: number;
  size?: number;
  width?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={fill || 'currentColor'}
      height={size || height || 24}
      width={size || width || 24}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z"
      />
    </svg>
  );
};

const ArchiveIcon = ({
  fill,
  height = 24,
  size,
  width = 24,
  ...props
}: {
  fill?: string;
  height?: number;
  size?: number;
  width?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={fill || 'currentColor'}
      height={size || height || 24}
      width={size || width || 24}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
      />
    </svg>
  );
};

const MoonIcon = ({
  fill,
  height = 24,
  size,
  width = 24,
  ...props
}: {
  fill?: string;
  height?: number;
  size?: number;
  width?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={fill || 'currentColor'}
      height={size || height || 24}
      width={size || width || 24}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
      />
    </svg>
  );
};

const SunIcon = ({
  fill,
  height = 24,
  size,
  width = 24,
  ...props
}: {
  fill?: string;
  height?: number;
  size?: number;
  width?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={fill || 'currentColor'}
      height={size || height || 24}
      width={size || width || 24}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
      />
    </svg>
  );
};

const LogoImage = ({
  height = 24,
  size,
  width = 24,
}: {
  height?: number;
  size?: number;
  width?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size || height || 24}
      width={size || width || 24}
      zoomAndPan="magnify"
      viewBox="0 0 375 374.999991"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="id1">
          <path
            d="M 26.5625 230 L 175 230 L 175 375 L 26.5625 375 Z M 26.5625 230 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="id2">
          <path
            d="M 26.5625 46 L 170 46 L 170 190 L 26.5625 190 Z M 26.5625 46 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="id3">
          <path
            d="M 205 0 L 348.3125 0 L 348.3125 144 L 205 144 Z M 205 0 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#id1)">
        <path
          fill="#ff3399"
          d="M 26.652344 375 L 100.671875 230.609375 L 174.691406 375 Z M 80.679688 341.976562 L 120.664062 341.976562 L 100.671875 302.976562 Z M 80.679688 341.976562 "
          fillOpacity="1"
          fillRule="evenodd"
        />
      </g>
      <g clipPath="url(#id2)">
        <path
          fill="#4ee6b3"
          d="M 169.652344 100.589844 L 169.652344 134.8125 L 115.21875 134.8125 L 115.21875 189.265625 L 81.007812 189.265625 L 81.007812 134.8125 L 26.574219 134.8125 L 26.574219 100.589844 L 81.007812 100.589844 L 81.007812 46.132812 L 115.21875 46.132812 L 115.21875 100.589844 Z M 169.652344 100.589844 "
          fillOpacity="1"
          fillRule="evenodd"
        />
      </g>
      <path
        fill="#00a2e9"
        d="M 247.347656 179.78125 C 288.230469 179.78125 321.375 212.9375 321.375 253.835938 C 321.375 294.734375 288.230469 327.890625 247.347656 327.890625 C 206.464844 327.890625 173.320312 294.734375 173.320312 253.835938 C 173.320312 212.9375 206.464844 179.78125 247.347656 179.78125 Z M 247.347656 214.003906 C 225.34375 214.003906 207.527344 231.828125 207.527344 253.835938 C 207.527344 275.847656 225.347656 293.671875 247.347656 293.671875 C 269.351562 293.671875 287.167969 275.847656 287.167969 253.835938 C 287.167969 231.828125 269.351562 214.003906 247.347656 214.003906 Z M 247.347656 214.003906 "
        fillOpacity="1"
        fillRule="evenodd"
      />
      <g clipPath="url(#id3)">
        <path
          fill="#ffcc00"
          d="M 205.222656 0 L 348.300781 0 L 348.300781 143.132812 L 205.222656 143.132812 Z M 239.429688 34.21875 L 239.429688 108.914062 L 314.09375 108.914062 L 314.09375 34.21875 Z M 239.429688 34.21875 "
          fillOpacity="1"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

const LogoText = ({
  height = 24,
  size,
  width = 24,
}: {
  height?: number;
  size?: number;
  width?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      zoomAndPan="magnify"
      viewBox="0 0 375 225"
      height={size || height || 24}
      width={size || width || 24}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <g />
      </defs>
      <g fill="#4ee6b3" fillOpacity="1">
        <g transform="translate(60.32399, 168.930397)">
          <g>
            <path d="M 12.625 0 L 12.625 -12.625 L 0 -12.625 L 0 -101 L 12.625 -101 L 12.625 -113.609375 L 63.125 -113.609375 L 63.125 -101 L 75.75 -101 L 75.75 -88.375 L 50.5 -88.375 L 50.5 -101 L 25.25 -101 L 25.25 -12.625 L 50.5 -12.625 L 50.5 -50.5 L 37.875 -50.5 L 37.875 -63.125 L 75.75 -63.125 L 75.75 0 Z M 12.625 0 " />
          </g>
        </g>
      </g>
      <g fill="#00a2e9" fillOpacity="1">
        <g transform="translate(165.858761, 168.930397)">
          <g>
            <path d="M 12.625 0 L 12.625 -25.25 L 0 -25.25 L 0 -113.609375 L 25.25 -113.609375 L 25.25 -25.25 L 37.875 -25.25 L 37.875 -101 L 63.125 -101 L 63.125 -25.25 L 75.75 -25.25 L 75.75 -113.609375 L 101 -113.609375 L 101 -25.25 L 88.375 -25.25 L 88.375 0 L 63.125 0 L 63.125 -12.625 L 37.875 -12.625 L 37.875 0 Z M 12.625 0 " />
          </g>
        </g>
      </g>
      <g fill="#ff3399" fillOpacity="1">
        <g transform="translate(296.638078, 168.930397)">
          <g>
            <path d="M 0 0 L 0 -113.609375 L 75.75 -113.609375 L 75.75 -101 L 25.25 -101 L 25.25 -63.125 L 63.125 -63.125 L 63.125 -50.5 L 25.25 -50.5 L 25.25 0 Z M 0 0 " />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const icons = {
  archive: <ArchiveIcon fill="var(--nextui-colors-secondary)" size={30} />,
  bolt: <BoltIcon fill="var(--nextui-colors-warning)" size={30} />,
  chevron: <ChevronDownIcon fill="currentColor" size={16} />,
  fire: <FireIcon fill="var(--nextui-colors-error)" size={30} />,
  logoImage: <LogoImage size={26} />,
  logoText: <LogoText width={45} height={35} />,
  moon: <MoonIcon fill="currentColor" size={24} />,
  sun: <SunIcon fill="currentColor" size={24} />,
};
