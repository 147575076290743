import * as React from 'react';
import { Button, useTheme } from '@nextui-org/react';
import { useTheme as useNextTheme } from 'next-themes';
import { icons } from '@src/layouts/app/AppNavBarIcons';

interface AppThemeToggleProps {}

/**
 * @name AppThemeToggle
 * @description tbd...
 */
export function AppThemeToggle(_props: AppThemeToggleProps) {
  // const {} = props

  // Hooks
  const { isDark } = useTheme();
  const { setTheme } = useNextTheme();

  // Handlers
  const onToggleTheme = () => {
    setTheme(isDark ? 'light' : 'dark');
  };

  return (
    <Button
      auto
      light
      ripple={false}
      icon={isDark ? icons.moon : icons.sun}
      onClick={onToggleTheme}
    />
  );
}
